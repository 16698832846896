export const sessionConstants = {
  CREATE_SESSION_REQUEST: "CREATE_SESSION_REQUEST",
  CREATE_SESSION_SUCCESS: "CREATE_SESSION_SUCCESS",
  CREATE_SESSION_FAILURE: "CREATE_SESSION_FAILURE",

  GET_SESSION_REQUEST: "GET_SESSION_REQUEST",
  GET_SESSION_SUCCESS: "GET_SESSION_SUCCESS",
  GET_SESSION_FAILURE: "GET_SESSION_FAILURE",

  SEARCH_SESSION: "SEARCH_SESSION",
  GET_ALL_SESSIONS_REQUEST: "GET_ALL_SESSIONS_REQUEST",
  GET_ALL_SESSIONS_SUCCESS: "GET_ALL_SESSIONS_SUCCESS",
  GET_ALL_SESSIONS_FAILURE: "GET_ALL_SESSIONS_FAILURE",

  UPDATE_SESSION_REQUEST: "UPDATE_SESSION_REQUEST",
  UPDATE_SESSION_SUCCESS: "UPDATE_SESSION_SUCCESS",
  UPDATE_SESSION_FAILURE: "UPDATE_SESSION_FAILURE",

  DELETE_SESSION_REQUEST: "DELETE_SESSION_REQUEST",
  DELETE_SESSION_SUCCESS: "DELETE_SESSION_SUCCESS",
  DELETE_SESSION_FAILURE: "DELETE_SESSION_FAILURE",

  GET_GROUPS_REQUEST: "GET_GROUPS_REQUEST",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAILURE: "GET_GROUPS_FAILURE",

  GET_BUSINESS_UNITS_REQUEST: "GET_BUSINESS_UNITS_REQUEST",
  GET_BUSINESS_UNITS_SUCCESS: "GET_BUSINESS_UNITS_SUCCESS",
  GET_BUSINESS_UNITS_FAILURE: "GET_BUSINESS_UNITS_FAILURE",

  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_FAILURE",

  GET_BRANDS_REQUEST: "GET_BRANDS_REQUEST",
  GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
  GET_BRANDS_FAILURE: "GET_BRANDS_FAILURE",

  GET_MEDIA_TACTICS_REQUEST: "GET_MEDIA_TACTICS_REQUEST",
  GET_MEDIA_TACTICS_SUCCESS: "GET_MEDIA_TACTICS_SUCCESS",
  GET_MEDIA_TACTICS_FAILURE: "GET_MEDIA_TACTICS_FAILURE",

  GET_SESSION_KPI_REQUEST: "GET_SESSION_KPI_REQUEST",
  GET_SESSION_KPI_SUCCESS: "GET_SESSION_KPI_SUCCESS",
  GET_SESSION_KPI_FAILURE: "GET_SESSION_KPI_FAILURE",
};
