import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Divider,
  Dropdown,
  Menu,
  Icon,
  Tooltip,
} from 'antd';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import XLSX from 'xlsx';
import { ExportSheet } from 'react-xlsx-sheet';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';

import './style.css';
class FormStepOne extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: null,
      file: {},
      isEditEnabled: false,
      cols: [],
    };
    this.renderEditable = this.renderEditable.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.submittedValues(this.state.data);
        this.props.handleNextButton();
      }
    });
  };
  componentDidMount() {
    if (this.props.baseScenario) {
      this.setState({
        data: this.props.baseScenario,
      });
    }

    // let fileName = TestFile;
    // let workbook = XLSX.readFile(fileName);
    // console.log(workbook); //should print an array with the excel file data
  }

  componentDidUpdate(prevProps) {
    if (this.props.baseScenario !== prevProps.baseScenario) {
      if (this.props.baseScenario) {
        this.setState({
          data: this.props.baseScenario,
        });
      }
    }
  }
  renderEditable(cellInfo) {
    return (
      <NumberFormat
        decimalScale={
          cellInfo.column.id === 'media_elasticity'
            ? 3
            : cellInfo.column.id === 'gp_uc'
            ? 3
            : 0
        }
        thousandSeparator={true}
        style={{ backgroundColor: '#fafafa', width: '100%' }}
        contentEditable
        suppressContentEditableWarning
        onValueChange={(e) => this.handleNumberFormatChange(e, cellInfo)}
        value={this.state.data[cellInfo.index][cellInfo.column.id]}
      />
    );
  }
  handleNumberFormatChange = (e, cellInfo) => {
    let self = this;
    if (this.state.isEditEnabled) {
      const data = [...this.state.data];
      data[cellInfo.index][cellInfo.column.id] = e.floatValue;
      this.setState({ data });
    } else {
      setTimeout(function () {
        self.setState(
          {
            isEditEnabled: false,
          },
          () => self.confirmAlert('table', e, cellInfo)
        );
      }, 10);
    }
  };

  // formatNumber = (val) => {
  //   return val ? val.toLocaleString() : 0;
  // };
  formatNumber = (val) => {
    return val ? parseFloat(val.toFixed(0)).toLocaleString() : 0;
  };

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.clearFile(files[0]);
  }

  clearFile = (file) => {
    this.setState({ file: {}, loading: true }, () => this.setFile(file));
  };
  setFile = (files) => {
    this.setState({ file: files, loading: true }, () => this.handleFile());
  };
  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      var formatedData = this.state.data.map(function (item, index) {
        return {
          gp: item.gp ? item.gp : null,
          spend_share: item.spend_share ? item.spend_share : null,
          profit: item.profit ? item.profit : null,
          roi_b: item.roi_b ? item.roi_b : null,
          saturation_parameter: item.saturation_parameter
            ? item.saturation_parameter
            : null,
          session_id: item.session_id ? item.session_id : null,
          factor: item.factor ? item.factor : null,
          uplift: item.uplift ? item.uplift : null,
          modified_on: item.modified_on ? item.modified_on : null,
          grp: item.grp ? item.grp : null,
          group: data[index]['Business Divisions']
            ? data[index]['Business Divisions']
            : null,
          business_unit: data[index]['Business Unit']
            ? data[index]['Business Unit']
            : null,
          country: data[index].Country ? data[index].Country : null,
          brand: data[index].Brand ? data[index].Brand : null,
          media_tactic: data[index]['Media Type']
            ? data[index]['Media Type']
            : null,

          spend: data[index].Spend ? data[index].Spend : null,
          cost_per_point: data[index]['Cost Per Point']
            ? data[index]['Cost Per Point']
            : null,
          shipments: data[index].Sales ? data[index].Sales : null,
          gp_uc: data[index]['Gross Profit']
            ? data[index]['Gross Profit']
            : null,
          media_elasticity: data[index]['Media Elasticity']
            ? data[index]['Media Elasticity']
            : null,
        };
      });

      this.setState(
        {
          data: formatedData,
        },
        () => this.hideloading()
      );
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  hideloading = () => {
    this.setState(
      {
        loading: false,
      },
      () => toast.success('Scenario data imported successfully')
    );
  };
  renderKpiSection = ({
    media_spend,
    media_volume,
    media_gross_profit,
    media_shipments,
  }) => {
    return (
      <>
        <Col span={4} className="text-center border-right">
          <h5 className="text-muted mb-3">MEDIA SPEND</h5>
          <b className="text-dark"> $ {this.formatNumber(media_spend)}</b>
        </Col>
        <Col span={4} className="text-center border-right">
          <h5 className="text-muted mb-3">MEDIA VOLUME</h5>
          <b className="text-dark">{this.formatNumber(media_volume)}</b>
        </Col>
        <Col span={4} className="text-center border-right">
          <h5 className="text-muted mb-3">MEDIA GROSS PROFIT</h5>
          <b className="text-dark">$ {this.formatNumber(media_gross_profit)}</b>
        </Col>
        <Col span={4} className="text-center">
          <h5 className="text-muted mb-3">SHIPMENTS</h5>
          <b className="text-dark">{this.formatNumber(media_shipments)}</b>
        </Col>
      </>
    );
  };

  confirmAlert = (type, e, cellInfo) => {
    let self = this;
    swal({
      title: 'Are you sure?',
      text: 'Once edited, you will not be able to revert changes!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((i) => {
      if (type === 'file') {
        if (i) {
          self.setState(
            {
              isEditEnabled: true,
            },
            () => self.upload()
          );
        } else {
          self.setState({
            isEditEnabled: false,
          });
        }
      }
      if (type === 'table') {
        if (i) {
          self.setState(
            {
              isEditEnabled: true,
            },
            () => self.handleNumberFormatChange(e, cellInfo)
          );
        } else {
          self.setState({
            isEditEnabled: false,
          });
        }
      }
    });
  };
  upload = () => {
    let self = this;
    self.setState({ file: {} });
    document.getElementById('file-input').click();
  };

  onFileChange = () => {
    if (this.state.isEditEnabled) {
      this.upload();
    } else {
      this.confirmAlert('file');
    }
  };

  getDataSourceForExport = () => {
    let header = [
      { title: 'Business Divisions', dataIndex: 'group' },
      { title: 'Business Unit', dataIndex: 'business_unit' },
      { title: 'Country', dataIndex: 'country' },
      { title: 'Brand', dataIndex: 'brand' },
      { title: 'Media Type', dataIndex: 'media_tactic' },
      { title: 'Support', dataIndex: 'grp' },
      { title: 'Spend', dataIndex: 'spend' },
      { title: 'Cost Per Point', dataIndex: 'cost_per_point' },
      { title: 'Sales', dataIndex: 'shipments' },
      { title: 'Gross Profit', dataIndex: 'gp_uc' },
      { title: 'Profit', dataIndex: 'gp' },
      { title: 'Media Elasticity', dataIndex: 'media_elasticity' },
    ];

    let data = this.state.data;

    return {
      header,
      data,
    };
  };
  render() {
    const { get_session_kpi, get_session } = this.props.session;
    const { get_scenario_kpi } = this.props.scenario;
    const { session_title, start_date, end_date } = get_session.data;

    return (
      <div>
        <>
          <Row gutter={[16, 16]} className="d-flex align-items-center">
            <Col span={8}>
              <h4 className="session-title">
                {session_title}
                <span className="mt-2">
                  {start_date} - {end_date}
                </span>
              </h4>
            </Col>

            {this.props.ScenarioId
              ? this.renderKpiSection(get_scenario_kpi.data)
              : this.renderKpiSection(get_session_kpi.data)}
          </Row>

          <Divider></Divider>

          <Col span={24} className="mt-3 ">
            <Row gutter={[16, 16]} className="d-flex align-items-center w-100">
              <Col span={12}>
                <h3 className="session-title text-dark mb-4">Scenario Data</h3>
              </Col>
              <Col span={12} className="ml-auto text-right">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item className="py-2 border-bottom">
                        <Button type="link" onClick={this.onFileChange}>
                          <input
                            type="file"
                            name="file"
                            id="file-input"
                            accept=".xlsx"
                            onChange={this.handleChange}
                            style={{ width: 100 }}
                            hidden
                          />
                          <Icon type="upload" /> Import
                        </Button>
                      </Menu.Item>
                      <Menu.Item className="py-2 ">
                        <ExportSheet
                          header={this.getDataSourceForExport().header}
                          fileName={`scenario_data`}
                          dataSource={this.getDataSourceForExport().data}
                          xlsx={XLSX}
                        >
                          <Button type="link">
                            <Icon type="download" /> Export
                          </Button>
                        </ExportSheet>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomLeft"
                >
                  <Button type="primary">
                    Actions <Icon type="caret-down" />
                  </Button>
                </Dropdown>
              </Col>
            </Row>

            <ReactTable
              data={this.state.data}
              columns={[
                {
                  Header: (
                    <Tooltip
                      placement="topLeft"
                      title={<span>Business Divisions</span>}
                    >
                      Business Divisions
                    </Tooltip>
                  ),

                  accessor: 'group',
                  // Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip
                      placement="topLeft"
                      title={<span>Business Unit</span>}
                    >
                      Business Unit
                    </Tooltip>
                  ),

                  accessor: 'business_unit',
                  // Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip placement="topLeft" title={<span>Country</span>}>
                      Country
                    </Tooltip>
                  ),
                  accessor: 'country',
                  // Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip placement="topLeft" title={<span>Brand</span>}>
                      Brand
                    </Tooltip>
                  ),
                  accessor: 'brand',
                  // Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip
                      placement="topLeft"
                      title={<span>Media Type</span>}
                    >
                      Media Type
                    </Tooltip>
                  ),
                  accessor: 'media_tactic',
                  // Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip placement="topLeft" title={<span>Support</span>}>
                      Support
                    </Tooltip>
                  ),
                  accessor: 'grp',
                  // Cell: this.renderEditable,
                  className: 'text-center',
                  Cell: (props) => (
                    <div> {this.formatNumber(props.value)} </div>
                  ),
                },
                {
                  Header: (
                    <Tooltip placement="topLeft" title={<span>Spend</span>}>
                      Spend
                    </Tooltip>
                  ),
                  accessor: 'spend',
                  Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip
                      placement="topLeft"
                      title={<span>Cost Per Point</span>}
                    >
                      Cost Per Point
                    </Tooltip>
                  ),
                  accessor: 'cost_per_point',
                  className: 'text-center',
                  Cell: this.renderEditable,
                },
                {
                  Header: (
                    <Tooltip placement="topLeft" title={<span>Sales</span>}>
                      Sales
                    </Tooltip>
                  ),
                  accessor: 'shipments',
                  Cell: this.renderEditable,
                  className: 'text-center',
                },
                {
                  Header: (
                    <Tooltip
                      placement="topLeft"
                      title={<span>Gross Profit</span>}
                    >
                      Gross Profit
                    </Tooltip>
                  ),
                  accessor: 'gp_uc',
                  Cell: this.renderEditable,
                  className: 'text-center',
                },

                {
                  Header: (
                    <Tooltip
                      placement="topLeft"
                      title={<span>Media Elasticity</span>}
                    >
                      Media Elasticity
                    </Tooltip>
                  ),

                  accessor: 'media_elasticity',
                  Cell: this.renderEditable,
                  className: 'text-center',
                },
              ]}
              defaultPageSize={10}
              className="-striped -highlight"
              showPagination={false}
              loading={
                this.props.scenario.base_scenario.loading || this.state.loading
              }
              pageSize={this.state.data.length ? this.state.data.length : 4}
              noDataText={
                !this.props.scenario.base_scenario.loading && 'No data found'
              }
            />
          </Col>

          <Form onSubmit={this.handleSubmit} className="text-center mb-0">
            <Divider></Divider>

            <Form.Item className="text-center mb-0">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 160 }}
                disabled={
                  this.props.scenario.base_scenario.loading &&
                  this.state.data.length === 0
                }
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      </div>
    );
  }
}

const WrappedFormStepOne = Form.create({ name: 'normal_login' })(FormStepOne);

const mapStateToProps = (state) => ({
  session: state.session,
  scenario: state.scenario,
});

export default connect(mapStateToProps)(WrappedFormStepOne);
