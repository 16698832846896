import React from "react";
import { Card } from "antd";
class Dashbpard extends React.Component {
  state = {};

  render() {
    return (
      <div>
        <Card className="mb-4" title={"Title"}>
          Temp
        </Card>
      </div>
    );
  }
}

export default Dashbpard;
