export const scenarioConstants = {
  GET_SCENARIO_REQUEST: "GET_SCENARIO_REQUEST",
  GET_SCENARIO_SUCCESS: "GET_SCENARIO_SUCCESS",
  GET_SCENARIO_FAILURE: "GET_SCENARIO_FAILURE",

  CREATE_SCENARIO_REQUEST: "CREATE_SCENARIO_REQUEST",
  CREATE_SCENARIO_SUCCESS: "CREATE_SCENARIO_SUCCESS",
  CREATE_SCENARIO_FAILURE: "CREATE_SCENARIO_FAILURE",

  UPDATE_SCENARIO_REQUEST: "UPDATE_SCENARIO_REQUEST",
  UPDATE_SCENARIO_SUCCESS: "UPDATE_SCENARIO_SUCCESS",
  UPDATE_SCENARIO_FAILURE: "UPDATE_SCENARIO_FAILURE",

  DELETE_SCENARIO_REQUEST: "DELETE_SCENARIO_REQUEST",
  DELETE_SCENARIO_SUCCESS: "DELETE_SCENARIO_SUCCESS",
  DELETE_SCENARIO_FAILURE: "DELETE_SCENARIO_FAILURE",

  GET_BASE_SCENARIO_REQUEST: "GET_BASE_SCENARIO_FAILURE",
  GET_BASE_SCENARIO_SUCCESS: "GET_BASE_SCENARIO_SUCCESS",
  GET_BASE_SCENARIO_FAILURE: "GET_BASE_SCENARIO_FAILURE",

  GET_SCENARIOS_REQUEST: "GET_SCENARIOS_REQUEST",
  GET_SCENARIOS_SUCCESS: "GET_SCENARIOS_SUCCESS",
  GET_SCENARIOS_FAILURE: "GET_SCENARIOS_FAILURE",

  GET_GRANULAR_DATA_REQUEST: "GET_GRANULAR_DATA_REQUEST",
  GET_GRANULAR_DATA_SUCCESS: "GET_GRANULAR_DATA_SUCCESS",
  GET_GRANULAR_DATA_FAILURE: "GET_GRANULAR_DATA_FAILURE",

  RUN_SCENARIO_REQUEST: "RUN_SCENARIO_REQUEST",
  RUN_SCENARIO_SUCCESS: "RUN_SCENARIO_SUCCESS",
  RUN_SCENARIO_FAILURE: "RUN_SCENARIO_FAILURE",

  GET_SCENARIO_KPI_REQUEST: "GET_SCENARIO_KPI_REQUEST",
  GET_SCENARIO_KPI_SUCCESS: "GET_SCENARIO_KPI_SUCCESS",
  GET_SCENARIO_KPI_FAILURE: "GET_SCENARIO_KPI_FAILURE",

  GET_OPTIMIZED_DATA_REQUEST: "GET_OPTIMIZED_DATA_REQUEST",
  GET_OPTIMIZED_DATA_SUCCESS: "GET_OPTIMIZED_DATA_SUCCESS",
  GET_OPTIMIZED_DATA_FAILURE: "GET_OPTIMIZED_DATA_FAILURE",
};
