import React, { Component } from 'react';
import { Card, Row, Col, Steps, Breadcrumb, Button } from 'antd';
import StepOne from './FormStepOne';
import StepTwo from './FormStepTwo';
import StepThree from './FormStepThree';
import StepFinal from './FormStepFinal';
import { sessionActions } from '../../actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { Icon } from 'antd';
const { Step } = Steps;
const dateFormat = 'YYYY-MM-DD';
class FinalForm extends Component {
  state = {
    step: 0,
    step_one_fields: {
      bu_list: [],
      group_list: [],
      country_list: [],
      mt_list: [],
      brand_list: [],
    },
    step_two_fields: {
      start_date: undefined,
      end_date: undefined,
    },
    step_three_fields: {
      session_title: undefined,
      session_description: undefined,
    },
    step_final_fields: {
      bu_list: [],
      group_list: [],
      country_list: [],
      mt_list: [],
      brand_list: [],
      start_date: null,
      end_date: null,
      session_title: undefined,
      session_description: undefined,
    },
    show_final_values: false,
    help_steps: [
      {
        title: 'Step 1',
        content: `Define the scope by selecting appropriate Group, Business Unit, Country, Brand and Media Tactic(s).`,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 5,
        target: '#step0',
        disableBeacon: true,
        styles: {
          options: {
            width: 400,
          },
        },
      },
      {
        title: 'Step 2',
        spotlightPadding: 5,
        content: (
          <ul>
            <li>
              Start Date - End Date defines the scope for the base data setup.
            </li>
            <li>
              Based on the date range selection, all the historical media spend,
              media driven volume and profit along with shipments will be
              reflecting under Base Scenario.
            </li>
            <li>
              Base scenario data once defined cannot be edited directly. During
              scenario creation, You can use edit/copy options to modify the
              data for future period simulations.
            </li>
            <li>
              If you wish to run the optimization at semi-annual level, proxy
              historical time frame with 6 month gap has to be selected. You can
              modify the inputs later while running scenarios.
            </li>
          </ul>
        ),
        styles: {
          options: {
            width: 900,
          },
        },
        target: '#step1',

        disableBeacon: true,
      },
      {
        content: `You need to provide a title to the session and can add appropriate description.`,
        spotlightPadding: 5,
        styles: {
          options: {
            width: 400,
          },
        },
        target: '#step2',
        title: 'Step 3',

        disableBeacon: true,
      },
      {
        content: `You need to click on Save Session before proceeding to scenario creation.`,
        spotlightPadding: 5,
        target: '#step3',
        styles: {
          options: {
            width: 400,
          },
        },
        title: 'Step 4',
        disableBeacon: true,
      },
    ],
    run: false,
  };

  componentDidMount() {
    // let d = new Date();
    let pastYear = '2019';
    document.body.classList.remove('login');
    if (this.getSessionId()) {
      this.getSession();
    } else {
      this.setState({
        step_two_fields: {
          start_date: moment(`${pastYear}-01-01`, dateFormat),
          end_date: moment(`${pastYear}-12-01`, dateFormat),
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.session.get_session.data !== prevProps.session.get_session.data
    ) {
      if (this.props.session.get_session.data) {
        this.setState({
          step_one_fields: {
            bu_list: this.props.session.get_session.data.business_unit,
            group_list: this.props.session.get_session.data.group,
            country_list: this.props.session.get_session.data.country,
            mt_list: this.props.session.get_session.data.media_tactic,
            brand_list: this.props.session.get_session.data.brand,
          },

          step_two_fields: {
            start_date: moment(
              this.props.session.get_session.data.start_date,
              dateFormat
            ),
            end_date: moment(
              this.props.session.get_session.data.end_date,
              dateFormat
            ),
          },
          step_three_fields: {
            session_title: this.props.session.get_session.data.session_title,
            session_description: this.props.session.get_session.data
              .session_title,
          },
        });
      }
    }
  }
  toTimestamp = (strDate) => {
    var d = new Date(strDate);
    var n = d.getTime();

    return n;
  };
  getSession = () => {
    const { dispatch } = this.props;
    dispatch(sessionActions.getSession(this.getSessionId()));
  };
  handleNextButton = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleBackButton = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  getSessionId = () => {
    if (this.props.match.params.sessionId)
      return this.props.match.params.sessionId;
  };
  getLabel = () => {
    if (this.props.match.params.sessionId) {
      return 'Edit session';
    } else {
      return 'Create session';
    }
  };
  handleConfirmButton = (values) => {
    const { step_final_fields } = this.state;
    this.setState(
      {
        step_final_fields: {
          ...step_final_fields,
          ...values,
        },
      },
      () =>
        this.setState({ show_final_values: true }, () =>
          this.getSessionId()
            ? this.updateSession(this.state.step_final_fields)
            : this.createSession(this.state.step_final_fields)
        )
    );
  };

  createSession = (data) => {
    const { dispatch } = this.props;
    dispatch(sessionActions.createSession(data));
  };
  updateSession = (data) => {
    const { dispatch } = this.props;
    dispatch(sessionActions.updateSession(this.getSessionId(), data));
  };
  getFinalStepValue = (values) => {
    const { step_final_fields } = this.state;
    this.setState({
      step_final_fields: {
        ...step_final_fields,
        ...values,
      },
    });
  };

  getStepOneValue = (values) => {
    const { step_one_fields } = this.state;

    this.setState({
      step_one_fields: {
        ...step_one_fields,
        ...values,
      },
    });
  };

  getStepTwoValue = (values) => {
    const { step_two_fields } = this.state;
    this.setState({
      step_two_fields: {
        ...step_two_fields,
        ...values,
      },
    });
  };
  getStepThreeValue = (values) => {
    const { step_three_fields } = this.state;
    this.setState({
      step_three_fields: {
        ...step_three_fields,
        ...values,
      },
    });
  };
  renderStepForm = (current) => {
    const {
      step,
      step_one_fields,
      step_two_fields,
      step_three_fields,
      step_final_fields,
    } = this.state;

    const steps = [
      {
        title: 'Step 1',
        description: 'Session Setup',
        content: (
          <StepOne
            {...step_one_fields}
            handleNextButton={this.handleNextButton}
            submittedValues={this.getStepOneValue}
            session={this.props.session}
          />
        ),
      },
      {
        title: 'Step 2',
        description: 'Time Frame',
        content: (
          <StepTwo
            {...step_two_fields}
            handleNextButton={this.handleNextButton}
            handleBackButton={this.handleBackButton}
            submittedValues={this.getStepTwoValue}
          />
        ),
      },
      {
        title: 'Step 3',
        description: 'Session Title',
        content: (
          <StepThree
            {...step_three_fields}
            handleNextButton={this.handleNextButton}
            handleBackButton={this.handleBackButton}
            submittedValues={this.getStepThreeValue}
          />
        ),
      },
      {
        title: 'Step 4',
        description: 'Preview & Save',
        content: (
          <StepFinal
            {...step_final_fields}
            step_one_fields={step_one_fields}
            step_two_fields={step_two_fields}
            step_three_fields={step_three_fields}
            handleConfirmButton={this.handleConfirmButton}
            handleBackButton={this.handleBackButton}
            submittedValues={this.getFinalStepValue}
            session={this.props.session}
            id={this.getSessionId()}
          />
        ),
      },
    ];
    return (
      <>
        <Steps current={step} direction="horizontal">
          {steps.map((item, index) => (
            <Step
              key={item.title}
              title={item.title}
              description={<div id={`step${index}`}>{item.description}</div>}
              className="pb-4 mt-4"
            />
          ))}
        </Steps>

        <div className="steps-content">
          <div className="steps-content">{steps[step].content}</div>
        </div>
      </>
    );
  };
  getHelpers = (helpers) => {
    this.helpers = helpers;
  };
  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      run: true,
    });
  };
  handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ run: false });
    }
  };
  cardTitle = (
    <div className="d-flex align-items-center">
      <h3 className="text-dark mb-0">{this.getLabel()}</h3>
      <div className="ml-auto d-flex align-items-center">
        <div>
          <Button className="px-2" onClick={this.handleClickStart}>
            <Icon
              type="question-circle"
              className="mx-auto"
              theme="filled"
              style={{ fontSize: '16px' }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
  render() {
    const { step, run, help_steps } = this.state;
    return (
      <>
        <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={help_steps}
          styles={{
            tooltip: {
              fontSize: 14,
            },
            tooltipContainer: {
              textAlign: 'left',
            },
            tooltipTitle: {
              fontSize: 18,
              textAlign: 'center',
            },
            options: {
              primaryColor: '#226ca0',
              fontSize: 12,
              width: 900,
              zIndex: 1000,
            },
          }}
          locale={{
            last: 'Close',
            skip: 'Skip',
          }}
        />
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{this.getLabel()}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="outer-wrapper">
          <Card title={this.cardTitle}>
            <Row className="d-flex justify-content-center">
              <Col span={18}>{this.renderStepForm(step)}</Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(FinalForm);
