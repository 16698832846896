import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  Row,
  Col,
  Divider,
  Icon,
  Radio,
  DatePicker,
  Dropdown,
  Menu,
  InputNumber,
  Alert,
} from 'antd';
import NumberFormat from 'react-number-format';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { scenarioActions } from '../../actions';
import XLSX from 'xlsx';
import { ExportSheet } from 'react-xlsx-sheet';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Joyride, { STATUS } from 'react-joyride';
class FormStepTwo extends Component {
  constructor() {
    super();
    this.state = {
      scenario_title: '',
      aggregated_data: [],
      granular_data: [],
      is_agg_constraint: null,
      loading: null,
      file: {},
      cols: [],
      isEditEnabled: false,
      help_steps: [
        {
          content: (
            <div>
              You need to set the <strong>Lower Limit</strong> and{' '}
              <strong>Upper Limit</strong>. These limits (% changes) will be
              applied on Base Scenario or Spend inputs provided.
            </div>
          ),
          floaterProps: {
            disableAnimation: true,
          },
          spotlightPadding: 20,
          target: '#help0',
          disableBeacon: true,
        },
        {
          content:
            'Indicates the level below which Spend cannot drop post optimization.',
          placement: 'bottom',
          target: '#help1',
          disableBeacon: true,
        },
        {
          content: `Indicates the level above which Spend cannot be allocated post optimization.`,
          placement: 'top',
          target: '#help2',
          disableBeacon: true,
        },
      ],
      run: false,
      showUpperLimitError: false,
    };
    this.renderEditable1 = this.renderEditable1.bind(this);
    this.renderEditable2 = this.renderEditable2.bind(this);
    this.renderEditableUpperLimit = this.renderEditableUpperLimit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
  }

  componentDidMount() {
    if (this.props.aggregatedData) {
      this.setState({
        aggregated_data: this.props.aggregatedData,
      });
    }

    this.setState({
      is_agg_constraint: this.props.is_agg_constraint,
    });
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.clearFile(files[0]);
  }

  clearFile = (file) => {
    this.setState({ file: {}, loading: true }, () => this.setFile(file));
  };
  setFile = (files) => {
    this.setState({ file: files, loading: true }, () => this.handleFile());
  };

  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      let formatedData = this.state.granular_data.map(function (item, index) {
        return {
          grp: item.grp ? item.grp : null,
          spend_share: item.spend_share ? item.spend_share : null,
          profit: item.profit ? item.profit : null,
          roi_b: item.roi_b ? item.roi_b : null,
          saturation_parameter: item.saturation_parameter
            ? item.saturation_parameter
            : null,
          session_id: item.session_id ? item.session_id : null,
          media_elasticity: item.media_elasticity
            ? item.media_elasticity
            : null,
          shipments: item.shipments ? item.shipments : null,
          gp_uc: item.gp_uc ? item.gp_uc : null,
          cost_per_point: item.cost_per_point ? item.cost_per_point : null,
          factor: item.factor ? item.factor : null,
          gp: item.gp ? item.gp : null,
          uplift: item.uplift ? item.uplift : null,
          modified_on: item.modified_on ? item.modified_on : null,
          group: data[index]['Business Divisions']
            ? data[index]['Business Divisions']
            : null,
          business_unit: data[index]['Business Unit']
            ? data[index]['Business Unit']
            : null,
          country: data[index].Country ? data[index].Country : null,
          brand: data[index].Brand ? data[index].Brand : null,
          media_tactic: data[index]['Media Type']
            ? data[index]['Media Type']
            : null,
          spend: data[index].Spend ? data[index].Spend : null,
          spend_upper_limit: data[index]['Spend Upper Limt']
            ? data[index]['Spend Upper Limt']
            : null,
          spend_lower_limit: data[index]['Spend Lower Limit']
            ? data[index]['Spend Lower Limit']
            : null,
        };
      });

      this.setState(
        {
          granular_data: formatedData,
        },
        () => this.hideloading()
      );
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  hideloading = () => {
    this.setState(
      {
        loading: false,
      },
      () => toast.success('Granular data imported successfully')
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          bu_threshold_lower: this.state.aggregated_data.find(
            (x) => x.id === 'bu_threshold'
          ).lower_limit,
          bu_threshold_upper: this.state.aggregated_data.find(
            (x) => x.id === 'bu_threshold'
          ).upper_limit,
          country_threshold_lower: this.state.aggregated_data.find(
            (x) => x.id === 'country_threshold'
          ).lower_limit,
          country_threshold_upper: this.state.aggregated_data.find(
            (x) => x.id === 'country_threshold'
          ).upper_limit,
          brand_threshold_lower: this.state.aggregated_data.find(
            (x) => x.id === 'brand_threshold'
          ).lower_limit,
          brand_threshold_upper: this.state.aggregated_data.find(
            (x) => x.id === 'brand_threshold'
          ).upper_limit,
          media_threshold_lower: this.state.aggregated_data.find(
            (x) => x.id === 'media_threshold'
          ).lower_limit,
          media_threshold_upper: this.state.aggregated_data.find(
            (x) => x.id === 'media_threshold'
          ).upper_limit,
          grp_threshold_lower: this.state.aggregated_data.find(
            (x) => x.id === 'grp_threshold'
          ).lower_limit,
          grp_threshold_upper: this.state.aggregated_data.find(
            (x) => x.id === 'grp_threshold'
          ).upper_limit,
        };
        this.props.submittedValues(
          values,
          data,
          this.state.aggregated_data,
          this.state.granular_data
        );
        this.props.handleNextButton();
      }
    });
  };

  storeValues = () => {
    let data = {
      bu_threshold_lower: this.state.aggregated_data.find(
        (x) => x.id === 'bu_threshold'
      ).lower_limit,
      bu_threshold_upper: this.state.aggregated_data.find(
        (x) => x.id === 'bu_threshold'
      ).upper_limit,
      country_threshold_lower: this.state.aggregated_data.find(
        (x) => x.id === 'country_threshold'
      ).lower_limit,
      country_threshold_upper: this.state.aggregated_data.find(
        (x) => x.id === 'country_threshold'
      ).upper_limit,
      brand_threshold_lower: this.state.aggregated_data.find(
        (x) => x.id === 'brand_threshold'
      ).lower_limit,
      brand_threshold_upper: this.state.aggregated_data.find(
        (x) => x.id === 'brand_threshold'
      ).upper_limit,
      media_threshold_lower: this.state.aggregated_data.find(
        (x) => x.id === 'media_threshold'
      ).lower_limit,
      media_threshold_upper: this.state.aggregated_data.find(
        (x) => x.id === 'media_threshold'
      ).upper_limit,
      grp_threshold_lower: this.state.aggregated_data.find(
        (x) => x.id === 'grp_threshold'
      ).lower_limit,
      grp_threshold_upper: this.state.aggregated_data.find(
        (x) => x.id === 'grp_threshold'
      ).upper_limit,
    };

    const { getFieldsValue } = this.props.form;
    const values = getFieldsValue();
    this.props.submittedValues(
      values,
      data,
      this.state.aggregated_data,
      this.state.granular_data
    );
    this.props.handleBackButton();
  };
  mapArray = (d) => {
    let r = [];
    let a = [];
    let b = [];
    let c = [];
    let g = [];
    let f = [];
    if (d === null) {
      return [];
    } else {
      d.forEach((e) => {
        if (
          e['group'] !== null &&
          e['business_unit'] !== null &&
          e['country'] !== null &&
          e['brand'] !== null
        ) {
          a.unshift(e);
        } else if (
          e['group'] !== null &&
          e['business_unit'] === null &&
          e['country'] === null &&
          e['brand'] === null
        ) {
          b.unshift(e);
        } else if (
          e['group'] === null &&
          e['business_unit'] !== null &&
          e['country'] === null &&
          e['brand'] === null
        ) {
          c.unshift(e);
        } else if (
          e['group'] === null &&
          e['business_unit'] === null &&
          e['country'] !== null &&
          e['brand'] === null
        ) {
          g.unshift(e);
        } else if (
          e['group'] === null &&
          e['business_unit'] === null &&
          e['country'] === null &&
          e['brand'] !== null
        ) {
          f.unshift(e);
        } else {
          r.push(e);
        }
      });
      return [...a, ...b, ...c, ...g, ...f, ...r];
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.aggregatedData !== this.state.aggregated_data) {
      if (this.props.aggregatedData) {
        this.setState({
          aggregated_data: this.props.aggregatedData,
        });
      }
    }
    if (
      this.props.scenario.granular_data.data !==
        prevProps.scenario.granular_data.data &&
      !this.props.ScenarioId
    ) {
      if (this.props.scenario.granular_data.data) {
        this.setState({
          granular_data: this.mapArray(this.props.scenario.granular_data.data),
        });
      }
    }
    if (
      this.props.granular_data !== this.state.granular_data &&
      this.props.ScenarioId
    ) {
      if (this.state.granular_data) {
        this.setState({
          granular_data: this.props.granular_data,
          scenario_title: this.props.scenario_title,
        });
      }
    }
  }

  formatNumber = (val) => {
    return val ? parseFloat(val.toFixed(0)).toLocaleString() : 0;
  };
  renderEditable1(cellInfo) {
    return (
      <NumberFormat
        decimalScale={3}
        style={{ backgroundColor: '#fafafa', width: '100%' }}
        thousandSeparator={true}
        contentEditable
        suppressContentEditableWarning
        onValueChange={(e) => {
          const aggregated_data = [...this.state.aggregated_data];
          aggregated_data[cellInfo.index][cellInfo.column.id] = e.floatValue;
          this.setState({ aggregated_data });
        }}
        value={this.state.aggregated_data[cellInfo.index][cellInfo.column.id]}
      />
    );
  }
  renderEditable2(cellInfo) {
    return (
      <NumberFormat
        decimalScale={3}
        thousandSeparator={true}
        style={{ backgroundColor: '#fafafa', width: '100%' }}
        contentEditable
        suppressContentEditableWarning
        onValueChange={(e) => this.handleNumberFormatChange(e, cellInfo)}
        value={this.state.granular_data[cellInfo.index][cellInfo.column.id]}
      />
    );
  }

  validateUpperLimit = (spend, upperlimt) => {
    var checkUpperLimitAndSpend = this.state.granular_data.filter(
      (item) => item.spend > item.spend_upper_limit || item.spend === null
    );
    if (checkUpperLimitAndSpend.length) {
      this.setState({ showUpperLimitError: true });
    } else {
      this.setState({ showUpperLimitError: false });
    }
  };
  renderEditableUpperLimit(cellInfo) {
    return (
      <>
        <NumberFormat
          decimalScale={3}
          thousandSeparator={true}
          style={{
            backgroundColor: '#fafafa',
            width: '100%',
            border:
              cellInfo.original.spend > cellInfo.original.spend_upper_limit ||
              !cellInfo.original.spend_upper_limit
                ? '1px solid #F40000'
                : '1px solid rgba(0,0,0,0.1)',
          }}
          contentEditable
          suppressContentEditableWarning
          onValueChange={(e) => {
            this.handleNumberFormatChange(e, cellInfo);
            this.validateUpperLimit(
              e,
              cellInfo.original.spend,
              cellInfo.original.spend_upper_limit
            );
          }}
          value={this.state.granular_data[cellInfo.index][cellInfo.column.id]}
        />
      </>
    );
  }

  onBudgetConstChange = (e) => {
    this.setState({
      is_agg_constraint: e.target.value,
    });
    if (!e.target.value && !this.props.ScenarioId) {
      this.getGranularData(this.props.baseScenario);
    }
    if (
      !e.target.value &&
      this.props.ScenarioId &&
      this.state.granular_data == null
    ) {
      this.getGranularData(this.props.baseScenario);
    }
  };

  getGranularData = (data) => {
    const { dispatch } = this.props;
    dispatch(scenarioActions.getGranularData({ mod_base_table: data }));
  };

  upload = () => {
    let self = this;
    self.setState({ file: {} });
    document.getElementById('file-input').click();
  };
  onFileChange = () => {
    if (this.state.isEditEnabled) {
      this.upload();
    } else {
      this.confirmAlert('file');
    }
  };

  confirmAlert = (type, e, cellInfo) => {
    let self = this;
    swal({
      title: 'Are you sure?',
      text: 'Once edited, you will not be able to revert changes!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((i) => {
      if (type === 'file') {
        if (i) {
          self.setState(
            {
              isEditEnabled: true,
            },
            () => self.upload()
          );
        } else {
          self.setState({
            isEditEnabled: false,
          });
        }
      }
      if (type === 'table') {
        if (i) {
          self.setState(
            {
              isEditEnabled: true,
            },
            () => self.handleNumberFormatChange(e, cellInfo)
          );
        } else {
          self.setState(
            {
              isEditEnabled: false,
            },
            () => self.handleNumberFormatChange(e, cellInfo)
          );
        }
      }
    });
  };
  handleNumberFormatChange = (e, cellInfo) => {
    let self = this;
    if (this.state.isEditEnabled) {
      const granular_data = [...this.state.granular_data];
      granular_data[cellInfo.index][cellInfo.column.id] = e.floatValue;
      this.setState({ granular_data });
    } else {
      setTimeout(function () {
        self.setState(
          {
            isEditEnabled: false,
          },
          () => self.confirmAlert('table', e, cellInfo)
        );
      }, 10);
    }
  };
  getDataSourceForExport = () => {
    let header = [
      { title: 'SessionId', dataIndex: 'session_id' },
      { title: 'Business Divisions', dataIndex: 'group' },
      { title: 'Business Unit', dataIndex: 'business_unit' },
      { title: 'Country', dataIndex: 'country' },
      { title: 'Brand', dataIndex: 'brand' },
      { title: 'Media Type', dataIndex: 'media_tactic' },
      { title: 'Spend', dataIndex: 'spend' },
      { title: 'Spend Lower Limit', dataIndex: 'spend_upper_limit' },
      { title: 'Spend Upper Limt', dataIndex: 'spend_lower_limit' },
    ];

    let data = this.state.granular_data;

    return {
      header,
      data,
    };
  };
  getHelpers = (helpers) => {
    this.helpers = helpers;
  };
  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      run: true,
    });
  };
  handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ run: false });
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { help_steps, run } = this.state;

    return (
      <div>
        <>
          <Joyride
            callback={this.handleJoyrideCallback}
            continuous={true}
            getHelpers={this.getHelpers}
            run={run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={help_steps}
            styles={{
              tooltip: {
                fontSize: 14,
              },
              tooltipContainer: {
                textAlign: 'left',
              },
              tooltipTitle: {
                fontSize: 18,
                textAlign: 'center',
              },
              options: {
                // arrowColor: '#e3ffeb',
                // backgroundColor: '#e3ffeb',
                // overlayColor: 'rgba(79, 26, 0, 0.4)',
                // primaryColor: '#000',
                // textColor: '#004a14',
                fontSize: 12,
                width: 400,
                zIndex: 1000,
                primaryColor: '#226ca0',
              },
            }}
            locale={{
              last: 'Close',
              skip: 'Skip',
            }}
          />
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={30}>
              <Col span={8}>
                <Form.Item
                  className="mb-3 custom-radio moo"
                  label="Media Optimization Objective"
                >
                  {getFieldDecorator('is_max_vol', {
                    rules: [{ required: false, message: 'Cannot be empty!' }],
                    initialValue: this.props.is_max_vol,
                  })(
                    <Radio.Group size="large">
                      <Radio.Button value={true}>Max Volume</Radio.Button>
                      <Radio.Button value={false}>Max Profit</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Start Date" className="mb-3">
                  {getFieldDecorator('scenario_ed', {
                    rules: [{ required: false, message: 'Cannot be empty!' }],
                    initialValue: this.props.scenario_sd,
                  })(
                    <DatePicker
                      size="large"
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="End Date">
                  {getFieldDecorator('scenario_ed', {
                    rules: [{ required: false, message: 'Cannot be empty!' }],
                    initialValue: this.props.scenario_ed,
                  })(
                    <DatePicker
                      size="large"
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={30}>
              <Col span={8}>
                <Form.Item
                  className="mb-3 custom-radio ibc"
                  label="Input Budget Constraints"
                >
                  {getFieldDecorator('is_agg_constraint', {
                    rules: [{ required: false, message: 'Cannot be empty!' }],
                    initialValue: this.props.is_agg_constraint,
                  })(
                    <Radio.Group
                      size="large"
                      onChange={this.onBudgetConstChange}
                      disabled={this.props.ScenarioId}
                    >
                      <Radio.Button value={true}>Aggregated</Radio.Button>
                      <Radio.Button value={false}>Granular</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {this.state.is_agg_constraint === true && (
              <>
                <Row
                  gutter={[16, 16]}
                  className="d-flex align-items-center w-100"
                >
                  <Col span={12}>
                    <h3 className="mb-0 text-dark">Aggregated Data</h3>
                  </Col>
                  <Col span={12} className="ml-auto text-right">
                    <Button className="px-2" onClick={this.handleClickStart}>
                      <Icon
                        type="question-circle"
                        className="mx-auto"
                        theme="filled"
                        style={{ fontSize: '16px' }}
                      />
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="d-flex pt-2">
                  <Col span={24} className="mt-3 ">
                    <ReactTable
                      sortable={false}
                      data={this.state.aggregated_data}
                      columns={[
                        {
                          Header: () => (
                            <div className="my_custom_class" id={`help0`}>
                              Spend Constraints
                            </div>
                          ),
                          accessor: 'name',
                          // Cell: this.renderEditable,
                        },
                        {
                          Header: () => (
                            <div className="my_custom_class" id={`help1`}>
                              LOWER LIMIT (%)
                            </div>
                          ),
                          accessor: 'lower_limit',
                          Cell: this.renderEditable1,
                          className: 'text-center',
                        },
                        {
                          Header: () => (
                            <div className="my_custom_class" id={`help2`}>
                              UPPER LIMIT (%)
                            </div>
                          ),
                          accessor: 'upper_limit',
                          Cell: this.renderEditable1,
                          className: 'text-center',
                        },
                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                      showPagination={false}
                      loading={this.props.scenario.base_scenario.loading}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label="YOY Spend Change" className="mb-3">
                      {getFieldDecorator('yoy_change', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter yoy spend change',
                          },
                        ],
                        initialValue: this.props.yoy_change,
                      })(
                        <InputNumber
                          size="large"
                          placeholder="YOY Spend "
                          style={{ width: '100%' }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {this.state.is_agg_constraint === false && (
              <>
                <Row gutter={[16, 16]} className="pt-4">
                  <Col span={12}>
                    <h3 className="mb-0">Granular Data</h3>
                  </Col>
                  <Col span={12} className="ml-auto text-right">
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item className="py-2 border-bottom">
                            <Button type="link" onClick={this.onFileChange}>
                              <input
                                type="file"
                                name="file"
                                id="file-input"
                                accept=".xlsx"
                                onChange={this.handleChange}
                                style={{ width: 100 }}
                                hidden
                              />
                              <Icon type="upload" /> Import
                            </Button>
                          </Menu.Item>
                          <Menu.Item className="py-2 ">
                            <ExportSheet
                              header={this.getDataSourceForExport().header}
                              fileName={`granular_data`}
                              dataSource={this.getDataSourceForExport().data}
                              xlsx={XLSX}
                            >
                              <Button type="link">
                                <Icon type="download" /> Export
                              </Button>
                            </ExportSheet>
                          </Menu.Item>
                        </Menu>
                      }
                      placement="bottomLeft"
                    >
                      <Button type="primary">
                        Actions <Icon type="caret-down" />
                      </Button>
                    </Dropdown>
                  </Col>

                  <Col span={24}>
                    {this.state.showUpperLimitError && (
                      <Alert
                        message="Error"
                        description="Upper limit values should be greater than spend values"
                        type="error"
                      />
                    )}
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="d-flex pt-4">
                  <Col span={24} className="mt-3 ">
                    <ReactTable
                      sortable={false}
                      data={
                        this.state.granular_data ? this.state.granular_data : []
                      }
                      columns={[
                        {
                          Header: 'Business Divisions',
                          accessor: 'group',
                          // Cell: this.renderEditable2,
                          className: 'text-center',
                        },
                        {
                          Header: 'Business Unit',
                          accessor: 'business_unit',
                          // Cell: this.renderEditable2,
                          className: 'text-center',
                        },
                        {
                          Header: 'Country',
                          accessor: 'country',
                          // Cell: this.renderEditable2,
                          className: 'text-center',
                        },
                        {
                          Header: 'Brand',
                          accessor: 'brand',
                          // Cell: this.renderEditable2,
                          className: 'text-center',
                        },
                        {
                          Header: 'Media Type',
                          accessor: 'media_tactic',
                          // Cell: this.renderEditable2,
                          className: 'text-center',
                        },
                        {
                          Header: 'Spend ($) ',
                          accessor: 'spend',
                          // Cell: this.renderEditable2,
                          className: 'text-center',
                          Cell: (props) => (
                            <div> {this.formatNumber(props.value)} </div>
                          ),
                        },

                        {
                          Header: 'Spend Lower Limit ($)',
                          accessor: 'spend_lower_limit',
                          Cell: this.renderEditable2,
                          className: 'text-center',
                        },
                        {
                          Header: 'Spend Upper Limit ($)',
                          accessor: 'spend_upper_limit',
                          Cell: this.renderEditableUpperLimit,
                          className: 'text-center',
                        },
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                      showPagination={false}
                      loading={
                        this.props.scenario.granular_data.loading ||
                        this.state.loading
                      }
                      pageSize={
                        this.state.granular_data !== null &&
                        this.state.granular_data.length
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item label="YOY Spend Change" className="mb-3">
                      {getFieldDecorator('yoy_change', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter yoy spend change',
                          },
                        ],
                        initialValue: this.props.yoy_change,
                      })(
                        <InputNumber
                          size="large"
                          placeholder="YOY Spend "
                          style={{ width: '100%' }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Divider></Divider>
            <Form.Item className="text-center">
              <Button
                size="large"
                style={{ width: 160 }}
                type="default"
                onClick={this.storeValues}
              >
                Back
              </Button>
              <Button
                size="large"
                className="ml-3"
                style={{ width: 160 }}
                type="primary"
                onClick={this.handleSubmit}
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      </div>
    );
  }
}

const WrappedFormStepTwo = Form.create({ name: 'step_two' })(FormStepTwo);

const mapStateToProps = (state) => ({
  session: state.session,
  scenario: state.scenario,
});

export default connect(mapStateToProps)(WrappedFormStepTwo);
