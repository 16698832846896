import React from 'react';
import { Card, Breadcrumb, Icon, Button, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { scenarioActions } from '../actions';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import XLSX from 'xlsx';
import { ExportSheet } from 'react-xlsx-sheet';

class OptimizedData extends React.Component {
  state = {
    dataList: [],

    header: [
      { title: 'Scenario_id', dataIndex: 'scenario_id' },
      { title: 'Business Divisions', dataIndex: 'group' },
      { title: 'Business Unit', dataIndex: 'business_unit' },
      { title: 'Country', dataIndex: 'country' },
      { title: 'Brand', dataIndex: 'brand' },
      { title: 'Media Type', dataIndex: 'media_tactic' },
      { title: 'Support', dataIndex: 'grp' },
      { title: 'Optimized Support', dataIndex: 'grp_optimized' },
      { title: 'Factor', dataIndex: 'factor' },
      { title: 'Saturation', dataIndex: 'saturation_parameter' },
      { title: 'Media Elasticity', dataIndex: 'media_elasticity' },
      { title: 'Sales', dataIndex: 'shipments' },
      { title: 'Media Spend', dataIndex: 'spend' },
      { title: 'Optimized Media Spend', dataIndex: 'spend_optimized' },
      { title: 'Media Volume', dataIndex: 'uplift' },
      { title: 'Optimized Media Volume', dataIndex: 'uplift_optimized' },
      { title: 'Media Profit', dataIndex: 'profit' },
      { title: 'Optimized Media Profit', dataIndex: 'profit_optimized' },
      { title: 'Share of Spend', dataIndex: 'spend_share' },
      { title: 'Optimized Share of Spend', dataIndex: 'spend_share_optimized' },
      { title: 'Optimized Gross Profit', dataIndex: 'gp_optimized' },
      { title: 'ROI', dataIndex: 'roi_b' },
      { title: 'Optimized - ROI', dataIndex: 'roi_optimized' },
      { title: 'Profit', dataIndex: 'gp_uc' },
      { title: 'Cost Per Point', dataIndex: 'cost_per_point' },
      { title: 'Gross Profit', dataIndex: 'gp' },
      { title: 'Spend -  Lower Limit', dataIndex: 'spend_lower_limit' },
      { title: 'Spend - Upper Limit', dataIndex: 'spend_upper_limit' },
      { title: 'Modified Date', dataIndex: 'modified_on' },
    ],
  };

  componentDidMount() {
    if (this.getScenarioId()) {
      this.getOptimizedData(this.getScenarioId());
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.scenario.get_optimized_data !==
      prevProps.scenario.get_optimized_data
    ) {
      if (this.props.scenario.get_optimized_data) {
        this.setState({
          dataList: this.props.scenario.get_optimized_data.data,
        });
      }
    }
  }
  getSessionId = () => {
    if (this.props.match.params.sessionId)
      return this.props.match.params.sessionId;
  };
  getScenarioId = () => {
    if (this.props.match.params.scenarioId)
      return this.props.match.params.scenarioId;
  };

  getOptimizedData = () => {
    const { dispatch } = this.props;
    dispatch(scenarioActions.getOptimizedData(this.getScenarioId()));
  };
  formatNumber = (val, e) => {
    return val ? parseFloat(val.toFixed(e)).toLocaleString() : 0;
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={`/scenarios-list/${this.getSessionId()}`}>
              Scenarios
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Optimized Data</Breadcrumb.Item>
        </Breadcrumb>
        <div className="outer-wrapper">
          <Card
            title={
              <div className="d-flex align-items-center">
                <h3 className="text-dark mb-0"> Optimized Data </h3>

                <div className="ml-auto d-flex align-items-center">
                  <ExportSheet
                    header={this.state.header}
                    fileName={`optimized-data`}
                    dataSource={this.state.dataList}
                    xlsx={XLSX}
                  >
                    <Button type="link">
                      <Icon type="download" /> Export
                    </Button>
                  </ExportSheet>
                </div>
              </div>
            }
          >
            <div className="mt-5">
              <ReactTable
                data={this.state.dataList}
                columns={[
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Business Divisions</span>}
                      >
                        Business Divisions
                      </Tooltip>
                    ),
                    accessor: 'group',

                    className: 'text-center',
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Business Unit</span>}
                      >
                        Business Unit
                      </Tooltip>
                    ),

                    accessor: 'business_unit',

                    className: 'text-center',
                  },
                  {
                    Header: (
                      <Tooltip placement="topLeft" title={<span>Country</span>}>
                        Country
                      </Tooltip>
                    ),
                    accessor: 'country',

                    className: 'text-center',
                  },
                  {
                    Header: (
                      <Tooltip placement="topLeft" title={<span>Brand</span>}>
                        Brand
                      </Tooltip>
                    ),

                    accessor: 'brand',

                    className: 'text-center',
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Media Type</span>}
                      >
                        Media Type
                      </Tooltip>
                    ),
                    accessor: 'media_tactic',

                    className: 'text-center',
                  },
                  {
                    Header: (
                      <Tooltip placement="topLeft" title={<span>Support</span>}>
                        Support
                      </Tooltip>
                    ),
                    accessor: 'grp',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },

                  {
                    Header: (
                      <Tooltip placement="topLeft" title={<span>Sales</span>}>
                        Sales
                      </Tooltip>
                    ),
                    accessor: 'shipments',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Media Spend</span>}
                      >
                        Media Spend
                      </Tooltip>
                    ),
                    accessor: 'spend',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Optimized Media Spend </span>}
                      >
                        Optimized Media Spend
                      </Tooltip>
                    ),
                    accessor: 'spend_optimized',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Media Volume</span>}
                      >
                        Media Volume
                      </Tooltip>
                    ),

                    accessor: 'uplift',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Optimized Media Volume</span>}
                      >
                        Optimized Media Volume
                      </Tooltip>
                    ),
                    accessor: 'uplift_optimized',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Media Profit</span>}
                      >
                        Media Profit
                      </Tooltip>
                    ),
                    accessor: 'profit',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Optimized Media Profit</span>}
                      >
                        Optimized Media Profit
                      </Tooltip>
                    ),
                    accessor: 'profit_optimized',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip placement="topLeft" title={<span>ROI</span>}>
                        ROI
                      </Tooltip>
                    ),
                    accessor: 'roi_b',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 2)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Optimized ROI</span>}
                      >
                        Optimized ROI
                      </Tooltip>
                    ),
                    accessor: 'roi_optimized',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 2)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip placement="topLeft" title={<span>Profit</span>}>
                        Profit
                      </Tooltip>
                    ),
                    accessor: 'gp_uc',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 3)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Cost Per Point</span>}
                      >
                        Cost Per Point
                      </Tooltip>
                    ),
                    accessor: 'cost_per_point',
                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                  {
                    Header: (
                      <Tooltip
                        placement="topLeft"
                        title={<span>Gross Profit</span>}
                      >
                        Gross Profit
                      </Tooltip>
                    ),
                    accessor: 'gp',

                    className: 'text-center',
                    Cell: (props) => (
                      <div> {this.formatNumber(props.value, 0)} </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                showPagination={false}
                loading={this.props.scenario.get_optimized_data.loading}
                pageSize={
                  this.state.dataList.length === 0
                    ? 3
                    : this.state.dataList.length
                }
              />
              <Button type="primary">
                <a
                  href={`https://app.powerbi.com/groups/me/apps/ddecf308-8722-41b9-b06f-d082af77f92d/reports/c48429a8-a70d-409c-a442-d6a5ad27c254/ReportSection?filter=session_info/${this.getSessionId()}&scenario_info/${this.getScenarioId()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Report
                </a>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scenario: state.scenario,
});

export default connect(mapStateToProps)(OptimizedData);
