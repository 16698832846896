export const errorhandlerConstants = {
  HTTP_400_ERROR: 'HTTP_400_ERROR',
  HTTP_401_ERROR: 'HTTP_401_ERROR',
  HTTP_403_ERROR: 'HTTP_403_ERROR',
  HTTP_404_ERROR: 'HTTP_404_ERROR',
  HTTP_500_ERROR: 'HTTP_500_ERROR',
  HTTP_502_ERROR: 'HTTP_502_ERROR',
  HTTP_OTHER_ERROR: 'HTTP_OTHER_ERROR',
  HTTP_NETWORK_ERROR: 'HTTP_NETWORK_ERROR',
  HTTP_503_ERROR: 'HTTP_503_ERROR',
};
