import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Form,
  Breadcrumb,
  Card,
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  Alert,
} from 'antd';
import appLayer from '../assets/app-layer.png';
import techArch from '../assets/techArch.png';
import icon1 from '../assets/icon-1.png';
import icon2 from '../assets/icon-2.png';
import icon3 from '../assets/icon-3.png';
import icon4 from '../assets/icon-4.png';
import icon5 from '../assets/icon-5.png';
import icon6 from '../assets/icon-6.png';
import icon7 from '../assets/icon-7.png';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

class Help extends Component {
  state = {
    activeKey: '1',
  };

  state = {
    pageNumber: 0,
    collapsed: false,
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    document.body.classList.add('help-section');
  }

  componentWillUnmount() {
    document.body.classList.remove('help-section');
  }
  handleClick = (e) => {
    if (e && e.key) this.setState({ activeKey: e.key });
  };
  render() {
    const { activeKey } = this.state;

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">Home</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Help</Breadcrumb.Item>
        </Breadcrumb>
        <div className="outer-wrapper help-section">
          <Layout>
            <Sider
              trigger={null}
              width={280}
              collapsible={false}
              collapsed={this.state.collapsed}
            >
              <Menu
                onClick={this.handleClick}
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4']}
                // selectedKeys={selectedKeys}
                // openKeys={openKeys}
                style={{ height: '100%', borderRight: 0 }}
              >
                <SubMenu key="sub1" title={<span>Introduction</span>}>
                  <Menu.Item key="1">User Guide</Menu.Item>
                  <Menu.Item key="2">Process Flow</Menu.Item>
                  <Menu.Item key="3">Definitions and Conventions</Menu.Item>
                </SubMenu>
                <SubMenu key="sub2" title={<span>Running Optimization</span>}>
                  {/* <Menu.Item key="4">Description</Menu.Item> */}
                  <Menu.Item key="5">
                    Create and Edit Model Specification
                  </Menu.Item>
                  <Menu.Item key="6">Model Definition</Menu.Item>
                </SubMenu>
                <SubMenu key="sub3" title={<span>Methodology</span>}>
                  {/* <Menu.Item key="7">Description</Menu.Item> */}
                  <Menu.Item key="8">Model Data and Structure</Menu.Item>
                  <Menu.Item key="9">Proxy Approach details</Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub4"
                  title={<span>Application Architecture</span>}
                >
                  <Menu.Item key="10">Technical Architecture</Menu.Item>
                  <Menu.Item key="11">Application Layer</Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>

            <Content
              style={{
                margin: '0 0 0 20px',
                padding: 0,
                background: 'transparent',
              }}
            >
              {/* Introduction  */}
              {(activeKey === '1' || activeKey === undefined) && (
                <Card title="User Guide" className="mb-5">
                  <div style={{ padding: '10x 0' }}>
                    <h2
                      style={{ fontSize: '34px' }}
                      className="text-primary font-weight-semi-bold mb-3"
                    >
                      Introduction
                    </h2>
                    <p style={{ fontSize: '20px' }}>
                      This document describes the process including Optimization
                      methodology, Proxy estimation approach, and technical
                      architecture used in the application.
                    </p>
                  </div>
                </Card>
              )}
              {activeKey === '2' && (
                <Card title="Process Flow" className="mb-5">
                  <Row>
                    <Col span={6}>
                      <div className="process-flow">1. Create Session</div>
                    </Col>
                    <Col span={18}>
                      <ul className="check-list">
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />{' '}
                          Define scope (Group, BU, Country, Brand, Media Tactic)
                        </li>
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />{' '}
                          Set time period
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6}>
                      <div className="process-flow">2. Create Scenario</div>
                    </Col>
                    <Col span={18}>
                      <ul className="check-list">
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />
                          Input/Edit Data and Assumptions
                        </li>
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />
                          Define Objective (Volume / Profit)
                        </li>
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />
                          Set Constraints (Aggregated / Granular)
                        </li>
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />
                          Run Scenario
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6}>
                      <div className="process-flow no-arrow">3. Reports</div>
                    </Col>
                    <Col span={18}>
                      <ul className="check-list">
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />
                          View reports to review the results in Power BI
                        </li>
                        <li>
                          <Icon
                            type="check-square"
                            theme="filled"
                            className="mr-2"
                          />
                          Can also extract the detailed report directly through
                          the application
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              )}
              {activeKey === '3' && (
                <Card title="Definitions and Conventions" className="mb-5">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th width="25%">Variable</th>
                        <th width="45%">Description</th>
                        <th width="30%">Source</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Shipments</td>
                        <td>Annual Unit Cases</td>
                        <td>Global Compass</td>
                      </tr>
                      <tr>
                        <td>Media Spend</td>
                        <td>Media Investment</td>
                        <td>Media Vault</td>
                      </tr>
                      <tr>
                        <td>CPP</td>
                        <td>Cost Per Point</td>
                        <td>Media Vault / MVA Models</td>
                      </tr>
                      <tr>
                        <td>MVA</td>
                        <td>Marketing Variance Analysis</td>
                        <td>Country Level MVA / Meta Study</td>
                      </tr>
                      <tr>
                        <td>Media Elasticity</td>
                        <td>Used for estimation of media impact</td>
                        <td>MVA / Meta Study</td>
                      </tr>
                      <tr>
                        <td>Media Volume</td>
                        <td>
                          Estimated volume driven for a given level of media
                          spend
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Media Gross Profit</td>
                        <td>
                          Estimated gross profit driven for a given level of
                          media spend
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              )}
              {/* End Introduction */}
              {/* Running Optimization */}
              {/* {activeKey === '4' && (
                <Card title="Description" className="mb-5">
                  <div style={{ padding: '80px 0' }}>
                    <h2
                      style={{ fontSize: '34px' }}
                      className="text-primary font-weight-semi-bold mb-3"
                    >
                      Running Optimization
                    </h2>
                    <p style={{ fontSize: '20px' }}>
                      This section describes the process of scenario creation
                      along with underlying assumptions and definitions being
                      used
                    </p>
                  </div>
                </Card>
              )} */}
              {activeKey === '5' && (
                <Card
                  className="mb-5"
                  title="This section describes the process of scenario creation
                  along with underlying assumptions and definitions being
                  used."
                >
                  <ul>
                    <li>
                      Create and Edit a Model Specification through Scenarios
                    </li>
                    <li className="mb-6">
                      Unlimited model specifications can be created in media
                      simulator from the Sessions page
                    </li>
                  </ul>

                  <p>
                    <b>Add a new Model Specification:</b> Sessions > Scenarios >
                    Create New Scenario > Scenario Data (Actions)
                  </p>
                  <p>
                    <b>Edit existing Model Specification:</b> Sessions >
                    Scenarios > Edit Scenario > Scenario Data (Actions)
                  </p>
                  <p>
                    <b>Copy and Edit existing Model Specification:</b> Sessions
                    > Scenarios > Copy Scenario > Scenario Data (Actions)
                  </p>
                  <p>
                    <b>Delete existing Model Specification:</b> Sessions >
                    Scenarios > Delete Scenario
                  </p>
                </Card>
              )}
              {activeKey === '6' && (
                <Card className="mb-5" title="Model Definition">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th width="15%">Field</th>
                        <th width="45%">Usage</th>
                        <th width="40%">Model Implication</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>GRPs</td>
                        <td>Volume estimation is based on the # of GRPs</td>
                        <td>
                          This metric cannot be edited directly. Estimated using
                          Spend / CPP.
                        </td>
                      </tr>
                      <tr>
                        <td>Spend</td>
                        <td>
                          Spend associated withs speicific Country-Brand-Media
                          Tactic combination
                        </td>
                        <td>
                          This metric can be edited and new spend inputs will be
                          used for optimization
                        </td>
                      </tr>
                      <tr>
                        <td>CPP</td>
                        <td>
                          Cost Per Point associated withs speicific
                          Country-Brand-Media Tactic combination
                        </td>
                        <td>
                          This metric can be edited to take into account latest
                          market inflation or media costs
                        </td>
                      </tr>
                      <tr>
                        <td>Shipments</td>
                        <td>
                          Brand Volume in Unit Cases for the selected period
                        </td>
                        <td>
                          Media Volume estimation is based on historical
                          Shipment levels. Edits can be made judiciously based
                          on additional analysis that supports the changes
                        </td>
                      </tr>
                      <tr>
                        <td>GP/UC</td>
                        <td>Brand Gross profit / Unit Case</td>
                        <td>
                          This metric can be edited to take into account latest
                          market conditions
                        </td>
                      </tr>
                      <tr>
                        <td>Media Elasticity</td>
                        <td>
                          MVA based elasticity for estimating media impact
                        </td>
                        <td>
                          Modifying the MVA based media elasticity will have
                          implications on overall results; Edits have to be made
                          judiciously.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              )}
              {/* End Running Optimization */}
              {/* Methodology */}
              {/* {activeKey === '7' && (
                <Card title="Description" className="mb-5">
                  <div style={{ padding: '80px 0' }}>
                    <h2
                      style={{ fontSize: '34px' }}
                      className="text-primary font-weight-semi-bold mb-3"
                    >
                      Methodology
                    </h2>
                    <p style={{ fontSize: '20px' }}>
                      This section describes the underlying methodology used to
                      estimate media volume
                    </p>
                  </div>
                </Card>
              )} */}
              {activeKey === '8' && (
                <Card
                  title="This section describes the underlying methodology used to estimate media volume"
                  className="mb-5"
                >
                  <h2
                    style={{ fontSize: '24px' }}
                    className="text-primary font-weight-semi-bold mb-5 mt-5"
                  >
                    Model Data and Structure
                  </h2>
                  <table className="w-100 mb-4">
                    <thead>
                      <tr>
                        <th
                          className="bg-white border-none"
                          style={{ borderBottom: 'none' }}
                        ></th>
                        <th>Factor</th>
                        <th>Description</th>
                        <th>Source</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon1} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Shipments</td>
                        <td>Unit Cases</td>
                        <td>Global Compass</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon2} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg"> Media spend/support</td>
                        <td>
                          Media budget, GRPs, Cost per Point, Media Inflation
                        </td>
                        <td>Media Vault</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon3} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Media Estimates</td>
                        <td>Elasticity, Decay rates, Saturation parameter</td>
                        <td>Meta Database</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon4} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Assumptions</td>
                        <td>Market level constraints, budget etc.</td>
                        <td>User Inputs</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon5} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Weather & Economy**</td>
                        <td>Temperature, Precipitation, Private Consumption</td>
                        <td>Weather 360, Compass</td>
                      </tr>
                    </tbody>
                  </table>

                  <p className="mt-5">**will be part of phase 3</p>

                  <hr />

                  <h2
                    style={{ fontSize: '24px' }}
                    className="text-primary font-weight-semi-bold mb-5 mt-5"
                  >
                    Media Optimization Methodology
                  </h2>

                  <Row className="d-flex align-items-center">
                    <Col span={8} className="bg-gray pr-4">
                      <div className="text-center">
                        <Icon
                          type="sound"
                          style={{ color: 'hotpink', fontSize: 42 }}
                        />
                      </div>

                      <h3 className="text-center font-weight-semi-bold mb-3 mt-5 text-dark">
                        Media Optimization
                      </h3>
                      <ul className="m-0">
                        <li className="pb-4">
                          What is the expected media volume for a given budget
                          and how do I optimize?
                        </li>
                        <li className="pb-4">
                          If there is additional 10/100MM budget, which
                          markets/brands/media channel to allocate?
                        </li>
                        <li className="pb-4">
                          If I have to cut media budget, where should that
                          happen?
                        </li>
                      </ul>
                    </Col>

                    <Col span={16}>
                      <Row className="bg-primary py-3 text-center">
                        <Col span={6}>
                          <h3 className="text-white mb-0 font-weight-semi-bold">
                            Input
                          </h3>
                        </Col>
                        <Col span={18}>
                          <h3 className="text-white mb-0 font-weight-semi-bold">
                            Methodology
                          </h3>
                        </Col>
                      </Row>
                      <Row className="py-4">
                        <Col span={6}>
                          <div className="circle">Media Elasticity</div>
                          <div className="circle">Shipments</div>
                          <div className="circle">
                            Media Spend and assumptions
                          </div>
                        </Col>
                        <Col span={18}>
                          <ul>
                            <li className="pb-3">
                              <span className="d-block pb-3">
                                MVA based media elasticities along with
                                saturation parameters are used to estimate Media
                                contribution %.
                              </span>
                              <span className="iphen pb-2">
                                Media Spend, GRPs, Cost per point, media
                                inflation are the inputs (from Media Vault) for
                                establishing the base media plan{' '}
                              </span>
                              <span className="iphen">
                                Media elasticities are estimated for non-MVA
                                brands through proxy based approach.
                              </span>
                            </li>
                            <li className="pb-3">
                              Shipments sourced from Global compass is another
                              input used to estimate the media contribution
                            </li>
                            <li className="pb-3">
                              Min-Max assumptions for selected
                              country-brand-media tactic provided by user will
                              be used as constraints
                            </li>
                            <li className="pb-3">
                              Optimization engine will recommend budget
                              allocation based on Max Volume or Max Profit
                              criteria
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              )}
              {activeKey === '9' && (
                <Card title="Proxy Approach details" className="mb-5">
                  <h2
                    style={{ fontSize: '24px' }}
                    className="text-primary font-weight-semi-bold mb-5 mt-5"
                  >
                    Data and Approach for Proxy estimates
                  </h2>

                  <table className="w-100 va-center">
                    <thead>
                      <tr>
                        <th
                          className="bg-white border-none"
                          style={{ borderBottom: 'none' }}
                        ></th>
                        <th>Variable</th>
                        <th>Description</th>
                        <th>Source</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon1} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Shipments</td>
                        <td>Annual Unit Cases</td>
                        <td>Global Compass</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon2} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Media Data</td>
                        <td>Media Investment</td>
                        <td>Media Vault</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon4} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Economy</td>
                        <td>Real Private Consumption</td>
                        <td>Global Compass</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon6} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Population</td>
                        <td>Population between 10-49 age group</td>
                        <td>Global Compass</td>
                      </tr>
                      <tr>
                        <td
                          className="bg-white border-none text-center"
                          style={{ borderBottom: 'none' }}
                        >
                          <img src={icon7} alt="" className="d-block mx-auto" />
                        </td>
                        <td className="bg">Media Penetration</td>
                        <td>Digital media Penetration, Households with TV</td>
                        <td>eMarketer, nakono</td>
                      </tr>
                    </tbody>
                  </table>

                  <ul className="check-list mt-6 font-weight-semi-bold">
                    <li>
                      <Icon
                        type="check-square"
                        theme="filled"
                        className="mr-2"
                      />{' '}
                      Market - Brand level data used for cluster analysis
                    </li>
                    <li>
                      <Icon
                        type="check-square"
                        theme="filled"
                        className="mr-2"
                      />{' '}
                      All variables are standardized{' '}
                    </li>
                    <li>
                      <Icon
                        type="check-square"
                        theme="filled"
                        className="mr-2"
                      />{' '}
                      Derived segments using k-means clustering technique{' '}
                    </li>
                    <li>
                      <Icon
                        type="check-square"
                        theme="filled"
                        className="mr-2"
                      />{' '}
                      Proxies are used for media penetration where data is not
                      available
                    </li>
                  </ul>

                  <hr />

                  <h2
                    style={{ fontSize: '24px' }}
                    className="text-primary font-weight-semi-bold mb-5 mt-5"
                  >
                    Analysis Results
                  </h2>

                  <table className="w-100">
                    <thead>
                      <tr className="text-center" style={{ fontSize: 13 }}>
                        <th>Cluster</th>
                        <th width="30%">Country Group</th>
                        <th>
                          Media Investment Index* <br />
                          (Group vs. Markets)
                        </th>
                        <th>
                          Non-MVA Markets <br />
                          (MI Index)
                        </th>
                        <th>
                          Average Digital Penetration <br />
                          (Group vs. Markets)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">1</td>
                        <td>
                          Argentina, Brazil, Chile, Colombia, Ecuador, South
                          Africa, Thailand, Turkey, Vietnam
                        </td>
                        <td>4.8% vs. 3.8%</td>
                        <td>Kazakhstan (3.8%)</td>
                        <td>69% vs. 69%</td>
                      </tr>
                      <tr>
                        <td className="text-center">2</td>
                        <td>
                          Egypt, Indonesia, Mexico, Morocco, Nigeria, Pakistan,
                          Peru, Philippines
                        </td>
                        <td>5.0% vs. 4.9%</td>
                        <td>Algeria (2%), Bolivia (4)%, Kenya (9%)</td>
                        <td>53% vs. 54%</td>
                      </tr>
                      <tr>
                        <td className="text-center">3</td>
                        <td>
                          Australia, Great Britain, Italy, Romania, Russian
                          Federation
                        </td>
                        <td>11.0% vs. 10%</td>
                        <td>Poland (10%)</td>
                        <td>72% vs. 77%</td>
                      </tr>
                      <tr>
                        <td className="text-center">4</td>
                        <td>France, Germany, Japan, Spain</td>
                        <td>13.3% vs. 11.5%</td>
                        <td>Belgium (10%), Netherlands (13%)</td>
                        <td>86% vs. 86%</td>
                      </tr>
                      <tr>
                        <td className="text-center">5</td>
                        <td> Brazil, China, India, Mexico, United States</td>
                        <td>8.5%</td>
                        <td></td>
                        <td>69%</td>
                      </tr>
                    </tbody>
                  </table>

                  <ul className="check-list mt-6 font-weight-semi-bold">
                    <li>
                      <Icon
                        type="check-square"
                        theme="filled"
                        className="mr-2"
                      />{' '}
                      Media elasticity Proxies will be based upon respective
                      country group and category average.
                    </li>
                  </ul>

                  <p>
                    * Media Investment (MI) Index = Media investment / Shipments{' '}
                    <br /> Cluster 5 includes all other MVA markets
                  </p>
                </Card>
              )}
              {/* End Methodology */}
              {/* Application Architecture */}
              {activeKey === '10' && (
                <Card title="Technical Architecture" className="mb-5">
                  <div className="txt-center">
                    <img
                      src={techArch}
                      alt=""
                      className="mx-auto d-block mb-4"
                    />
                  </div>

                  <Alert
                    message="DEPENDENCIES: System with required Configurations, Access/User Ids, Tools/Software Version and Licenses"
                    type="info"
                    className="text-center"
                  />
                </Card>
              )}
              {activeKey === '11' && (
                <Card title="Application Layer">
                  <div className="txt-center">
                    <img src={appLayer} alt="" className="mx-auto d-block" />
                  </div>
                </Card>
              )}
              {/*End Application Architecture */}
            </Content>
          </Layout>
          {/* <iframe
              title="help"
              src={helpDoc}
              style={{ height: "750px", width: "100%" }}
            /> */}
        </div>
      </div>
    );
  }
}

const WrappedRequestAccess = Form.create({ name: 'access' })(Help);

const mapStateToProps = (state) => ({
  signup: state.auth.signup,
});

export default connect(mapStateToProps)(WrappedRequestAccess);
