import React, { Component } from "react";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import "antd/dist/antd.css";
import "../index.css";
import { Form, Row, Button } from "antd";
import "../App.css";

import placeholder from "../assets/placeholder.png";
class RequestAccess extends Component {
  componentDidMount() {
    document.body.classList.remove("login");
    // document.body.classList.add("login");
    return () => {
      // document.body.classList.remove("login");
      document.body.classList.remove("login");
    };
  }

  render() {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ width: "100%", height: "100vh" }}
      >
        <div style={{ maxWidth: 450 }}>
          <div className="placeholder">
            <img src={placeholder} alt="" />
            <h3 className="text-primary"> Ae simulator Access</h3>
            <p>Click the below button to access ae simulator</p>

            <Button type="primary mr-2">
              <NavLink to="/"> Scenarios</NavLink>
            </Button>
            <Button type="primary">
              <NavLink to="/help"> Help</NavLink>
            </Button>
          </div>
        </div>
      </Row>
    );
  }
}

const WrappedRequestAccess = Form.create({ name: "access" })(RequestAccess);

const mapStateToProps = (state) => ({
  signup: state.auth.signup,
});

export default connect(mapStateToProps)(WrappedRequestAccess);
