import React, { Component } from 'react';
import { Card, Row, Col, Steps, Breadcrumb, Divider, Button, Icon } from 'antd';
import StepOne from './FormStepOne';
import StepTwo from './FormStepTwo';
import StepThree from './FormStepThree';
import { sessionActions, scenarioActions } from '../../actions';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment';
import Joyride, { STATUS } from 'react-joyride';
const { Step } = Steps;
const dateFormat = 'YYYY-MM-DD';
class FinalForm extends Component {
  state = {
    step: 0,
    step_one_fields: {
      bu_list: [],
      group_list: [],
      country_list: [],
      mt_list: [],
      brand_list: [],
    },
    step_two_fields: {
      session_id: null,
      scenario_sd: null,
      scenario_ed: null,
      is_max_vol: true,
      is_agg_constraint: true,
      bu_threshold_lower: -10,
      bu_threshold_upper: 10,
      country_threshold_lower: -10,
      country_threshold_upper: 10,
      brand_threshold_lower: -10,
      brand_threshold_upper: 10,
      media_threshold_lower: -10,
      media_threshold_upper: 10,
      grp_threshold_lower: -10,
      grp_threshold_upper: 10,
      yoy_change: 10,
    },
    step_three_fields: {
      scenario_title: undefined,
      scenario_description: undefined,
    },
    step_final_fields: {},
    show_final_values: false,
    baseScenario: [],
    aggregatedData: [
      {
        name: 'Group Threshold',
        id: 'grp_threshold',
        lower_limit: -10,
        upper_limit: 10,
      },
      {
        name: 'BU Threshold',
        id: 'bu_threshold',
        lower_limit: -10,
        upper_limit: 10,
      },
      {
        name: 'Country Threshold',
        lower_limit: -10,
        id: 'country_threshold',
        upper_limit: 10,
      },
      {
        name: 'Brand Threshold',
        id: 'brand_threshold',
        lower_limit: -10,
        upper_limit: 10,
      },
      {
        name: 'Media Tactic',
        id: 'media_threshold',
        lower_limit: -10,
        upper_limit: 10,
      },
    ],
    granular_data: [],
    help_steps: [
      {
        title: 'Step 1',

        content: (
          <ul>
            <li>
              You can edit Inputs <strong>(Scenario Data)</strong> either
              directly through UI or through <strong>Actions</strong>{' '}
              (Export/Import).
            </li>
            <li>
              You can modify <strong>Spend</strong> column at
              country-brand-media tactic level based on specific media plan
              instead of using base scenario spend.
            </li>
            <li>
              You can modify <strong>CPP (Cost per Point), GP/UC </strong>to
              reflect any changes from historical levels.
            </li>
            <li>
              <strong> Media Elasticity</strong> is calibrated based on
              historical MVAs for each country-brand-media tactic. Be extremely
              cautious while modifying the elasticity as this would have
              implications on the optimization result.
            </li>
            <li>
              You can edit <strong>Shipments</strong> but not recommended. If
              modified, estimation of media volume contribution (%) will be
              based on new shipments.
            </li>
          </ul>
        ),

        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 5,
        target: '#step0',
        placement: 'left',
        styles: {
          options: {
            width: 900,
          },
        },
        disableBeacon: true,
      },
      {
        title: 'Step 2',
        content: (
          <ul>
            <li>
              You need to select <strong>Media Optimization Objective</strong>{' '}
              and <strong>Input Budget Constraints</strong>
            </li>
            <li>
              You can provide constraints at <strong>Aggregated</strong> or{' '}
              <strong>Granular</strong> level.
            </li>
            <li>
              You need to select Granular option if you need to set different
              constraints for each individual entity in the data. Aggregate
              option would mean same constraints for all entities.
            </li>
          </ul>
        ),
        styles: {
          options: {
            width: 600,
          },
        },
        target: '#step1',
        spotlightPadding: 5,
        disableBeacon: true,
      },
      {
        content: `You need to provide a title to the scenario and add appropriate description.`,
        styles: {
          options: {
            width: 400,
          },
        },

        target: '#step2',
        title: 'Step 3',
        spotlightPadding: 5,
        disableBeacon: true,
      },
    ],
    run: false,
  };

  componentDidMount() {
    document.body.classList.remove('login');
    if (this.getSessionId() && !this.getScenarioId()) {
      this.getSession();
      this.getSessionKpi(this.getSessionId());
      this.getBaseScenario(this.getSessionId());
    }
    if (this.getScenarioId() && this.getSessionId()) {
      this.getSession();
      this.getScenario();
      this.getScenarioKpi(this.getSessionId());
      // this.getBaseScenario(this.getSessionId());
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.session.get_session.data !== prevProps.session.get_session.data
    ) {
      if (this.props.session.get_session.data) {
      }
    }
    if (
      this.props.session.get_session.data !== prevProps.session.get_session.data
    ) {
      if (this.props.session.get_session.data) {
        this.setState({
          step_two_fields: {
            ...this.state.step_two_fields,
            scenario_sd: moment(
              this.props.session.get_session.data.start_date,
              dateFormat
            ),
            scenario_ed: moment(
              this.props.session.get_session.data.end_date,
              dateFormat
            ),
            session_id: this.props.session.get_session.data.session_id,
          },
        });
      }
    }
    if (
      this.props.scenario.base_scenario.data !==
        prevProps.scenario.base_scenario.data &&
      !this.getScenarioId()
    ) {
      if (this.props.scenario.base_scenario.data) {
        this.setState({
          baseScenario: this.props.scenario.base_scenario.data,
        });
      }
    }
    if (
      this.props.scenario.scenario.data !== prevProps.scenario.scenario.data &&
      this.getScenarioId()
    ) {
      if (this.props.scenario.scenario.data) {
        this.setState({
          step_three_fields: {
            scenario_title: this.props.scenario.scenario.data.scenario_title,
            scenario_description: this.props.scenario.scenario.data
              .scenario_description,
          },

          baseScenario: this.props.scenario.scenario.data.scenario_data,

          aggregatedData: [
            {
              name: 'Group Threshold',
              id: 'grp_threshold',
              lower_limit: this.props.scenario.scenario.data
                .grp_threshold_lower,
              upper_limit: this.props.scenario.scenario.data
                .grp_threshold_upper,
            },
            {
              name: 'BU Threshold',
              id: 'bu_threshold',
              lower_limit: this.props.scenario.scenario.data.bu_threshold_lower,
              upper_limit: this.props.scenario.scenario.data.bu_threshold_upper,
            },
            {
              name: 'Country Threshold',
              lower_limit: this.props.scenario.scenario.data
                .country_threshold_lower,
              id: 'country_threshold',
              upper_limit: this.props.scenario.scenario.data
                .country_threshold_upper,
            },
            {
              name: 'Brand Threshold',
              id: 'brand_threshold',
              lower_limit: this.props.scenario.scenario.data
                .brand_threshold_lower,
              upper_limit: this.props.scenario.scenario.data
                .brand_threshold_upper,
            },
            {
              name: 'Media Threshold',
              id: 'media_threshold',
              lower_limit: this.props.scenario.scenario.data
                .media_threshold_lower,
              upper_limit: this.props.scenario.scenario.data
                .media_threshold_upper,
            },
          ],

          step_two_fields: {
            ...this.state.step_two_fields,
            is_max_vol: this.props.scenario.scenario.data.is_max_vol,
            is_agg_constraint: this.props.scenario.scenario.data
              .is_agg_constraint,
            yoy_change: this.props.scenario.scenario.data.yoy_change,
          },

          granular_data: this.props.scenario.scenario.data.granular_bounds,
          scenario_title: this.props.scenario.scenario.data.scenario_title,
        });
      }
    }
  }

  toTimestamp = (strDate) => {
    var d = new Date(strDate);
    var n = d.getTime();
    return n;
  };
  getSession = () => {
    const { dispatch } = this.props;
    dispatch(sessionActions.getSession(this.getSessionId()));
  };
  getSessionKpi = () => {
    const { dispatch } = this.props;
    dispatch(sessionActions.getSessionKpi(this.getSessionId()));
  };

  getScenarioKpi = (id) => {
    const { dispatch } = this.props;
    dispatch(scenarioActions.getScenarioKpi(id));
  };
  getBaseScenario = () => {
    const { dispatch } = this.props;
    dispatch(scenarioActions.getBaseScenario(this.getSessionId()));
  };
  getScenario = () => {
    const { dispatch } = this.props;
    dispatch(scenarioActions.getScenario(this.getScenarioId()));
  };

  createScenario = (data) => {
    const { dispatch } = this.props;
    dispatch(scenarioActions.createScenario(data));
  };

  updateScenario = (data) => {
    const { dispatch } = this.props;
    dispatch(
      scenarioActions.updateScenario(
        data,
        this.getScenarioId(),
        this.getSessionId()
      )
    );
  };

  handleNextButton = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleBackButton = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  getSessionId = () => {
    if (this.props.match.params.sessionId)
      return this.props.match.params.sessionId;
  };
  getScenarioId = () => {
    if (this.props.match.params.scenarioId)
      return this.props.match.params.scenarioId;
  };
  handleConfirmButton = (values) => {
    const {
      step_three_fields,
      step_two_fields,
      baseScenario,
      granularData,
    } = this.state;
    this.setState(
      {
        step_three_fields: {
          ...step_three_fields,
          ...values,
        },
        step_final_fields: {
          scenario_data: baseScenario,
          ...values,
          ...step_two_fields,
          scenario_sd: moment(step_two_fields.scenario_sd).format(dateFormat),
          scenario_ed: moment(step_two_fields.scenario_ed).format(dateFormat),
          granular_bounds: granularData,
        },
      },

      () =>
        this.getScenarioId()
          ? this.updateScenario(this.state.step_final_fields)
          : this.createScenario(this.state.step_final_fields)
    );
  };

  createSession = (data) => {
    const { dispatch } = this.props;
    dispatch(sessionActions.createSession(data));
  };
  updateSession = (data) => {
    const { dispatch } = this.props;
    dispatch(sessionActions.updateSession(this.getSessionId(), data));
  };

  getStepOneValue = (values) => {
    this.setState({
      baseScenario: values,
    });
  };

  getStepTwoValue = (
    values,
    aggregated_values,
    aggregatedData,
    granularData
  ) => {
    const { step_two_fields } = this.state;
    this.setState({
      ...this.state,
      step_two_fields: {
        ...step_two_fields,
        ...values,
        ...aggregated_values,
      },
      aggregatedData,
      granularData,
    });
  };
  getStepThreeValue = (values) => {
    const { step_three_fields } = this.state;
    this.setState({
      step_three_fields: {
        ...step_three_fields,
        ...values,
      },
    });
  };
  renderStepForm = (current) => {
    const {
      step,
      step_one_fields,
      step_two_fields,
      step_three_fields,
      baseScenario,
      aggregatedData,
      scenario_title,
    } = this.state;

    const steps = [
      {
        title: 'Step 1',
        description: 'Scenario Data',
        content: (
          <StepOne
            {...step_one_fields}
            handleNextButton={this.handleNextButton}
            submittedValues={this.getStepOneValue}
            session={this.props.session}
            baseScenario={baseScenario}
            ScenarioId={this.getScenarioId()}
          />
        ),
      },
      {
        title: 'Step 2',
        description: 'Optimization Setup',
        content: (
          <StepTwo
            {...step_two_fields}
            handleNextButton={this.handleNextButton}
            handleBackButton={this.handleBackButton}
            submittedValues={this.getStepTwoValue}
            aggregatedData={aggregatedData}
            baseScenario={baseScenario}
            ScenarioId={this.getScenarioId()}
            scenario_title={scenario_title}
            granular_data={this.state.granular_data}
          />
        ),
      },

      {
        title: 'Step 3',
        description: 'Scenario Setup',
        content: (
          <StepThree
            {...step_three_fields}
            handleNextButton={this.handleNextButton}
            handleBackButton={this.handleBackButton}
            submittedValues={this.getStepThreeValue}
            handleConfirmButton={this.handleConfirmButton}
            ScenarioId={this.getScenarioId()}
          />
        ),
      },
    ];
    return (
      <>
        <Steps current={step} direction="horizontal">
          {steps.map((item, index) => (
            <Step
              key={item.title}
              title={item.title}
              description={<div id={`step${index}`}>{item.description}</div>}
              className="pb-4 mt-4"
            />
          ))}
        </Steps>

        <Divider></Divider>

        <div className="steps-content">
          <div className="steps-content">{steps[step].content}</div>
        </div>
      </>
    );
  };
  getHelpers = (helpers) => {
    this.helpers = helpers;
  };
  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      run: true,
    });
  };
  handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ run: false });
    }
  };
  cardTitle = (
    <div className="d-flex align-items-center">
      <h3 className="text-dark mb-0">
        {this.getScenarioId() ? 'Edit Scenario' : 'Create Scenario'}
      </h3>
      <div className="ml-auto d-flex align-items-center">
        <div>
          <Button className="px-2" onClick={this.handleClickStart}>
            <Icon
              type="question-circle"
              className="mx-auto"
              theme="filled"
              style={{ fontSize: '16px' }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
  render() {
    const { step, help_steps, run } = this.state;

    return (
      <>
        <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={help_steps}
          styles={{
            tooltip: {
              fontSize: 14,
            },
            tooltipContainer: {
              textAlign: 'left',
            },
            tooltipTitle: {
              fontSize: 18,
              textAlign: 'center',
            },
            options: {
              // arrowColor: '#e3ffeb',
              // backgroundColor: '#e3ffeb',
              // overlayColor: 'rgba(79, 26, 0, 0.4)',
              // primaryColor: '#000',
              // textColor: '#004a14',
              fontSize: 12,
              // width: 900,
              zIndex: 1000,
              primaryColor: '#226ca0',
            },
          }}
          locale={{
            last: 'Close',
            skip: 'Skip',
          }}
        />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <NavLink to={`/scenarios-list/${this.getSessionId()}`}>
              Scenarios
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Scenario Detail</Breadcrumb.Item>
        </Breadcrumb>
        <div className="outer-wrapper">
          <Card title={this.cardTitle}>
            <Row className="d-flex justify-content-center">
              <Col span={24}>{this.renderStepForm(step)}</Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
  scenario: state.scenario,
});

export default connect(mapStateToProps)(FinalForm);
