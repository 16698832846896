let config = {};

if (window.REACT_APP_BASE_URL) {
  config = {
    baseURL: window.REACT_APP_BASE_URL,
  };
} else {
  config = {
    baseURL: "https://gms.k8s.analytic-edge.net",
  };
}

export const appConfig = {
  ...config,
};
