import React from 'react';
import { Input, Icon, Button, Popover, Row, Col } from 'antd';
import { NavLink } from 'react-router-dom';
import { sessionActions } from '../actions';
import { connect } from 'react-redux';
import Joyride, { STATUS } from 'react-joyride';
import Loading from './Loading/SessionItem';
import SessionItem from './sessions/SessionItem';
import placeholder from '../assets/placeholder.png';

class Dashboard extends React.Component {
  state = {
    list: [],
    help_steps: [
      {
        title: 'What is a Session?',
        content: (
          <ul>
            <li>
              You need to define the scope in terms of Country-brand-media
              tactics and time frame. The purpose of Session is to setup the{' '}
              <strong>Base data</strong> for running optimization.
            </li>

            <li>
              Click <strong>Create new session</strong> in case you need to
              define/modify the scope.
            </li>
            <li>
              Once a Session is saved, you can navigate through the Session home
              page and access all the sessions created under your id.
            </li>
          </ul>
        ),
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 5,
        target: '#dashboard',
        disableBeacon: true,
        styles: {
          options: {
            width: 550,
          },
        },
      },
    ],
    run: false,
  };

  componentDidMount() {
    this.props.dispatch(sessionActions.getSessions());
    document.body.classList.remove('login');
    return () => {
      document.body.classList.add('login');
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.session.get_sessions.data !==
      this.props.session.get_sessions.data
    ) {
      if (this.props.session.get_sessions.data)
        this.setState({
          list: this.props.session.get_sessions.data,
        });
    }
  }
  onSearch = (value) => {
    this.setState({
      list: this.props.session.get_sessions.data.filter((item) =>
        item.session_title
          ? item.session_title.toUpperCase().includes(value.toUpperCase())
          : item
      ),
    });
  };
  getHelpers = (helpers) => {
    this.helpers = helpers;
  };
  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      run: true,
    });
  };
  handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.setState({ run: false });
    }
  };

  render() {
    const { help_steps, run } = this.state;
    const { session } = this.props;
    const { get_sessions } = session;
    const { loading } = get_sessions;

    return (
      <>
        <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={help_steps}
          styles={{
            tooltip: {
              fontSize: 14,
            },
            tooltipContainer: {
              textAlign: 'left',
            },
            tooltipTitle: {
              fontSize: 18,
              textAlign: 'center',
            },
            options: {
              primaryColor: '#226ca0',
              fontSize: 12,
              width: 900,
              zIndex: 1000,
            },
          }}
          locale={{
            last: 'Close',
            skip: 'Skip',
          }}
        />
        <div className="outer-wrapper">
          <div className="title-bar d-flex align-items-center mb-5 border-bottom pb-3">
            <h3 className="font-weight-bold text-dark mb-0">Sessions</h3>
            <div className="ml-auto d-flex align-items-center">
              <div className="mr-3">
                <Input
                  addonAfter={<Icon type="search" />}
                  placeholder="Search sessions"
                  onChange={(e) => this.onSearch(e.target.value)}
                />
              </div>
              <div className="mr-3">
                <NavLink
                  to="/create-session"
                  className="ant-btn ant-btn-sm btn-primary-outline"
                >
                  Create new session
                </NavLink>
              </div>
              <div>
                <Popover placement="bottomRight">
                  <Button className="px-2" onClick={this.handleClickStart}>
                    <Icon
                      id="dashboard"
                      type="question-circle"
                      className="mx-auto"
                      theme="filled"
                      style={{ fontSize: '16px' }}
                    />
                  </Button>
                </Popover>
              </div>
            </div>
          </div>

          {(loading === true || this.props.delete_session.loading === true) && (
            <React.Fragment>
              <Row gutter={30}>
                {Array(6)
                  .fill('')
                  .map((e, i) => (
                    <Col span={8} className="mb-5" key={i}>
                      <Loading style={{ opacity: Number(2 / i).toFixed(1) }} />
                    </Col>
                  ))}
              </Row>
            </React.Fragment>
          )}
          {(loading === false ||
            this.props.delete_session.loading === false) && (
            <Row gutter={30}>
              {this.state.list.map((session, i) => (
                <Col span={8} className="mb-5" key={i}>
                  <SessionItem {...session} />
                </Col>
              ))}
            </Row>
          )}

          {(loading === false || this.props.delete_session.loading === false) &&
            this.state.list.length === 0 && (
              <div className="placeholder text-center bg-white rounded space-3 py-10">
                <img src={placeholder} alt="" className="mb-3" />
                <h3 className="text-primary mb-3">No Sessions Found!</h3>
                <p>
                  Sorry! You’ve not created any sessions so far, click the below
                  button to create one
                </p>

                <Button type="primary">
                  <NavLink to="/create-session"> Create New Sesssion</NavLink>
                </Button>
              </div>
            )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
  delete_session: state.session.delete_session,
});

export default connect(mapStateToProps)(Dashboard);
